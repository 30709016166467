<script setup lang="ts">
import type { ParsedContent } from '@nuxt/content'
import { slugify, slugifyId } from '../util'

const props = defineProps<{
  page: string
  level?: number | string
  full?: boolean
  overridetitle?: string
  notitle?: boolean
  nosubtitle?: boolean
  overridefragment?: string
}>()

type InlinePage = {
  fail: boolean
  document: ParsedContent | null
  defaultLevel: number
  section: string | null
  originalTitle: string | null
}

const inlinePage = await useAsyncData(props.page, async (): Promise<InlinePage> => {
  try {
    const res: InlinePage = {
      document: null,
      fail: false,
      section: null,
      defaultLevel: 2,
      originalTitle: null,
    }
    const search = slugify(props.page)
    res.document = await queryContent()
      .where({
        $or: [{ searchTitle: { $eq: search } }, { searchTags: { $contains: search } }],
      })
      .findOne()

    if (!res.document) {
      // does not have access to the page
      res.fail = true
      return res
    }

    if (props.overridefragment) {
      res.section = slugifyId(props.overridefragment)
    } else if (res.document?.tags?.[search] !== undefined) {
      const tag = res.document.tags[search]
      if (tag === '§') {
        res.section = slugifyId(search)
      } else if (tag) {
        res.section = slugifyId(tag)
      }
    }

    // console.log(JSON.stringify(res.document.body, null, 2))

    res.document.body!.children = res.document.body!.children.filter((i) => i.props?.noinline !== '')

    let full = props.full
    if (res.section) {
      const headers = ['', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']
      const index = res.document.body!.children.findIndex(
        (i) => headers.includes(i.tag!) && i.props?.id === res.section
      )
      if (index !== -1) {
        const header = res.document.body!.children[index]
        res.originalTitle = res.document.title!
        res.document.title = header.children?.map((p) => p.value).join('')
        res.document._path! += `#${header.props!.id}`
        res.defaultLevel = headers.indexOf(header.tag!)
        const section = res.document.body!.children[index + 1]
        if (section.children?.length === 1 && section.children?.[0].tag === 'em') {
          const subtitle = section.children?.[0].children?.[0].value
          res.document.subtitle = subtitle
          const text = res.document.body!.children[index + 2]
          res.document.body!.children = [text]
        } else {
          res.document.subtitle = undefined
          res.document.body!.children = [section]
        }
      } else {
        full = false
      }
    }

    if (!full) {
      const excerptTags = ['p', 'ul', 'table']
      res.document.body!.children = res.document.body!.children.filter((i) => excerptTags.includes(i.tag!)).slice(0, 1)
    }
    return res
  } catch (err) {
    console.error(err)
    return { fail: true, document: null, section: null, originalTitle: null, defaultLevel: 2 }
  }
})

const document = computed(() => inlinePage.data.value?.document)
</script>

<template>
  <div class="inline-page">
    <component
      :is="`h${level || inlinePage.data.value?.defaultLevel}`"
      v-if="document && !notitle"
      :id="slugifyId(document.title || page)"
      class="inline-title"
      :class="{
        rarity: !!document.rarity,
        [document.rarity?.replaceAll(' ', '')]: !!document.rarity,
      }"
    >
      <NuxtLink :to="document?._path" no-prefetch>
        <Icon name="fa6-solid:link" />
        {{ props.overridetitle || document.title }}
        <span v-if="inlinePage.data.value?.originalTitle" class="original-title"
          >({{ inlinePage.data.value?.originalTitle }})</span
        >
      </NuxtLink>
    </component>
    <PageSubtitle v-if="document && !nosubtitle" :item="document" :condensed="level === 6 || level === '6'" />
    <ContentRendererMarkdown
      v-if="document && document.body?.children.length"
      :value="document"
      class="nuxt-content inline"
    />
    <p v-else-if="!document" class="dm-only">&nbsp; Inline page "{{ page }}" not found.</p>
  </div>
</template>

<style lang="postcss">
.inline-page {
  .inline-title {
    a {
      color: var(--primary);
    }

    .original-title {
      display: none;
    }
  }

  .factbox,
  .statblock,
  blockquote {
    display: none;
  }

  .inline-subtitle {
    float: left;
    margin: 0 0.25em 0 0;
  }

  .item-type {
    margin-bottom: 0.75em !important;
  }

  &.preview-tooltip {
    .inline-title {
      display: flex;
      flex-direction: row;

      .original-title {
        display: inline;
        font-size: 0.75em;
        color: var(--text);
      }

      .subtitle {
        margin-bottom: 0.5em;
      }

      .icon {
        display: none;
      }
    }
  }
}
</style>
